<template>

  <header v-bind:style="{}">
    <h1>THOMAS</h1>
    <h2>BOGAR</h2>
    <button class="discover" @click="goto('navWrapper')"> <a id="discoverLink">Discover</a></button>
  </header>
  <div ref="navWrapper">
    <div id="navD" ref="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/resume">Resume</router-link> |
      <router-link to="/contact">Contact</router-link> |
      <router-link to="/blog">Blog</router-link>
    </div>
    <div id="navM" ref="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/resume">Resume</router-link> |
      <router-link to="/contact">Contact</router-link> |
      <router-link to="/blog">Blog</router-link>
    </div>
  </div>

  <router-view/>


  <footer>
    <ul id="footerul">
      <li><router-link to="/contact">Contact</router-link></li>
      <li><a href="https://www.linkedin.com/in/thomas-bogar-4b6686200/"><img class="linkedInImage" :src="require('./assets/linkedinsvg.svg')" alt="linkedin"></a></li>
      <li>Copyright © 2022 Thomas Bogar. All rights Reserved</li>

    </ul>
  </footer>
</template>

<style>

  .discover {
    margin-top: 20px;
    top: 0;
    left: 0;
    transition: all 0.15s linear 0s;
    position: relative;
    display: inline-block;
    padding: 15px 25px;
    background-color: #ffc100;
    text-transform: uppercase;
    color: #404040;
    letter-spacing: 1px;
    box-shadow: -6px 6px 0 #404040;
    text-decoration: none;
  }
  .discover:hover {
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 #404040;
  }
  .discover:hover::after {
    top: 1px;
    left: -2px;
    width: 4px;
    height: 4px;
  }
  .discover:hover::before {
    bottom: -2px;
    right: 1px;
    width: 4px;
    height: 4px;
  }
  .discover::after {
    transition: all 0.15s linear 0s;
    content: "";
    position: absolute;
    top: 2px;
    left: -4px;
    width: 8px;
    height: 8px;
    background-color: #404040;
    transform: rotate(45deg);
    z-index: -1;
  }
  .discover::before {
    transition: all 0.15s linear 0s;
    content: "";
    position: absolute;
    bottom: -4px;
    right: 2px;
    width: 8px;
    height: 8px;
    background-color: #404040;
    transform: rotate(45deg);
    z-index: -1;
  }



@media (max-width : 1450px) {
  #navD {
    display: none;
  }
  #navM {
    background-color: #2C4252;
    text-align: center;
    visibility: visible;
    font-family: 'Comfortaa', cursive;
    border-bottom: 5px solid  #ffc100;
    padding-bottom: 50px;
  }
  #navM a {
    text-decoration: none;
    font-size: 1.6em;
    display: block;
    color: white;
  }
  #navM a.router-link-exact-active {
    color: black;
    background-color: #ffc100;
    background-size: auto;

  }

}

@media (min-width : 1450px) {
  #navD {
    visibility: visible;
  }
  #navM {
    display: none;
  }
}

.linkedInImage {
  margin: 0 auto;
  width: 35px;
  transition: filter 0.2s;
}

.linkedInImage:hover {
  filter: invert(100%) saturate(1352%) ;
}

footer{
  background-color: #ffc100;
  text-align: center;
}

footer ul{
  /*justify-content: space-evenly;*/
  padding: 15px;
  font-family: "Signika", sans-serif;
  font-size: 15px;
  justify-content: center;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
}

footer img {
  vertical-align: middle;
  width: 60px;
}

footer ul li {
  display: block;margin-bottom: 10px;
}


.disabledRoute {
  opacity: .5;
}

#navD {
  text-align: center;
  font-family: 'Comfortaa', cursive;
  padding: 30px;
}

#navD a {
  color: black;
}

#navD a.router-link-exact-active {
  color: #008165;
}

#navD {
  /*background-color: #ffe600;*/
  background-color: #ffc100;
}

</style>

<script>
export default {
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    }
  }
};
</script>
